import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'

import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';

import footerImg from '../img/powered-by.png';
import logo from '../img/logo.png';

class DataPackListPage extends Component {
    render() {
        return (
            <div>

               {/*================================== top_bar ================================*/}
                <div className="topBar">
                    <span className='topBarInner'>
                        <span className='title'>Internet Packs
                        </span>
                        <Link to="/" className="homeIcon">
                            <i className="fa fa-home"></i>
                        </Link>
                    </span>
                </div>
                {/*===================================== end_top_bar ============================*/}

                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}
                
                <div className="whatsNewWrap robiNewsWrap">
                                    <div className="container">

                                        <h2>Select any one</h2>

                                        
                                        <div className="">

                                            {/* APP_4 datapack */}
                                                <div className="news24Item">
                                                    <Link to="/data_pack_app4" className="robiNewsWrapItemInner">
                                                        <div className="d-flex align-items-center itemInnerWrap">
                                                            <div className="itemImg">
                                                                <i className="fa fa-globe fa-2x"></i>
                                                            </div>
                                                            <div className="info">
                                                                <div className="title">
                                                                    1.2 GB
                                                                </div>
                                                                <p>Validity: 3 Days</p>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="shareIcon">
                                                            <span>
                                                                35 Tk &nbsp; &nbsp;<i className="fa fa-arrow-right"></i>
                                                            </span>
                                                        </div>

                                                    </Link>
                                                </div>
                                            {/* End APP_4 DATAPack */}

                                            {/* APP_16G datapack */}
                                                <div className="news24Item">
                                                    <Link to="/data_pack_app16" className="robiNewsWrapItemInner">
                                                        <div className="d-flex align-items-center itemInnerWrap">
                                                            <div className="itemImg">
                                                                <i className="fa fa-globe fa-2x"></i>
                                                            </div>
                                                            <div className="info">
                                                                <div className="title">
                                                                    11 GB <span className="badge badge-success">1 GB 4G</span>
                                                                </div>
                                                                <p>Validity: 7 Days</p>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="shareIcon">
                                                            <span>
                                                                148 Tk &nbsp; &nbsp;<i className="fa fa-arrow-right"></i>
                                                            </span>
                                                        </div>

                                                    </Link>
                                                </div>
                                            {/* End APP_16G DATAPack */}

                                            {/* SAP datapack */}
                                            <div className="news24Item">
                                                    <Link to="/data_pack_sap" className="robiNewsWrapItemInner">
                                                        <div className="d-flex align-items-center itemInnerWrap">
                                                            <div className="itemImg">
                                                                <i className="fa fa-globe fa-2x"></i>
                                                            </div>
                                                            <div className="info">
                                                                <div className="title">
                                                                    10 GB
                                                                </div>
                                                                <p>Validity: 30 Days</p>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="shareIcon">
                                                            <span>
                                                                330 Tk &nbsp; &nbsp;<i className="fa fa-arrow-right"></i>
                                                            </span>
                                                        </div>

                                                    </Link>
                                                </div>
                                            {/* End SAP DATAPack */}

                                        </div>



                                    </div>
                                </div>

                                <div className="footerImg text-center">
                                    <img src={footerImg} alt="robi" />
                                </div>
                
            </div>
        );
    }
}

export default DataPackListPage;