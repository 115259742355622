import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'
import { Container,Row,Col } from 'react-bootstrap';

import '../css/header.css';
import '../css/home.css';
import '../css/footer.css';

class PackErrorPage extends Component {
    render() {
        return (
            <div>
                <div className="error_img" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '90vh'}}>
                    <Container className="error_text">
                        <Row>
                            <Col sm={12}>Your are not valid for this DataPack!!</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to="/data_pack" style={{color:"red"}}>
                                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>Try again
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    {/* <div >

                            <br />
                                <span style={{fontSize:"100%"}}>Your are not valid for this DataPack!!</span>
                            <br />

                            <div style={{fontSize:"20px"}}>
                                <Link className="pl-5" to="/data_pack" style={{color:"red"}}>
                                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>Try again
                                </Link>
                            </div>
                        

                    </div> */}
                </div>
            </div>
        );
    }
}

export default PackErrorPage;