import React, {Component} from 'react';
import axios from "axios";
import '../../css/quiz.css';
import Loader from "react-loader-spinner";
import Main from "./Main";
import {Redirect} from "react-router-dom";


class Data extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }

    }
    componentDidMount() {

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/quiz/hard/v3/get_quiz',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                // this.setState({
                //     items: response.data.data
                // })
                const decodeData = Buffer.from(response.data, 'base64');
                const afterModify = JSON.parse(JSON.stringify(JSON.parse(decodeData).data));
                this.setState({
                    items: afterModify
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        if(!this.props.fromHomePage){
            return <Redirect to="hard_quiz" />
        }
        return (
            <div>
                { this.state.items.length > 0 ? <Main data={this.state.items}/> :
                    <div className="loader">
                        <Loader
                            type="Circles"
                            color="#dc1c2c"
                            height={50}
                            width={100}
                            timeout={1000} //3 secs
                        />
                    </div>
                }
            </div>
        );
    }
}

export default Data;