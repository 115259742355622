import React, { Component } from 'react';
import { ListGroup,Image } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

import '../css/header.css';
import '../css/radio.css';
import '../css/footer.css';

class CountryListPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            countrylist : []
        }
    }

    componentDidMount() {

        var config = {
            method: 'get',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_country_by_flag',
            headers: { 
              'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
          };
        axios(config)
        .then(res => {
            console.log(res.data.data)
          this.setState({
            countrylist: res.data.data
          });
        })

    }

    render() {
        return (
            <div>
                {/* top_bar */}
                <div className="topBar">
                    <span className='topBarInner'>
                        <span className='title'>Country list
                        </span>
                        <Link to="/" className="homeIcon">
                            <i className="fa fa-home"></i>
                        </Link>
                    </span>
                </div>
                {/* end_top_bar */}

                <ListGroup>
                    {this.state.countrylist.map((e, key) => {
                        return <ListGroup.Item>
                                <Link>
                                    <Image src={e.flag} className="rounded flag" />
                                    <span style={{padding: "15px"}}>{e.country}</span>
                                </Link>
                               </ListGroup.Item>
                    })}
                </ListGroup>
            </div>
        );
    }
}

export default CountryListPage;