import React, {Component} from 'react';
import {
    Link
} from 'react-router-dom'

import axios from 'axios';

import '../css/header.css';
import '../css/radio.css';
import '../css/footer.css';

import logo from '../img/logo.png'
import ReactAudioPlayer from 'react-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Loader from "react-loader-spinner";
import ReactPlayer from 'react-player'


class RadioPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            persons: [],
            radio: [],
            itemVal: [],
            close: true,
            playLink: '',
            isHls: '',
            playListLocal: '',
            isLoading: false
        }
    }

    componentDidMount() {

        console.log(this.state.itemVal, "after")

        this.setState({
            isLoading: true
        })

        var config = {
            method: 'get',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_country_by_flag',
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };
        axios(config)
            .then(res => {
                this.setState({
                    persons: res.data.data,
                    isLoading: false
                });
            })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    closeOpen = () => {
        this.setState({
            close: !this.state.close
        })
    }


    itemValue = (v) => {
        console.log(v)
        this.setState({
            itemVal: v,
            isLoading: true
        })
        const country_name = v.country;
        const data = JSON.stringify({"country": `${country_name}`});
        //var data = JSON.stringify({"country": "Bangladesh"});

        const config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_radio',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res.data.data)
                this.setState({
                    country: country_name,
                    radio: res.data.data,
                    isLoading: false
                });
            })


    }


    handleChange = (event) => {

        this.setState({value: event.target.value});
        var country_name = event.target.value;
        var data = JSON.stringify({"country": `${country_name}`});
        //var data = JSON.stringify({"country": "Bangladesh"});

        var config = {
            method: 'post',
            url: 'https://apiv1.duronto.com.bd/api/web/v1/get_radio',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data: data
        };

        axios(config)
            .then(res => {
                console.log(res.data.data)
                this.setState({
                    country: country_name,
                    radio: res.data.data
                });
            })
    };


    getPlayLink = (playLink) => {
        const link = playLink;
        const extension = link.substring(link.lastIndexOf(".") + 1);
        //alert(playLink);
        if(extension == 'm3u8'){
            this.setState({
                isHls: 'hls'
            })
        } else {
            this.setState({
                isHls: 'other'
            })
        }
        this.setState({
            playLink: playLink
        })
        localStorage.setItem('playLink', playLink);

        console.log(playLink, "playLink_playLink")
    }


    render() {
        var player;
        if(this.state.isHls == 'hls'){
             player = <ReactPlayer
                url={this.state.playLink}
                loop
                autoPlay
                controls
                config={{
                    file: {
                        forceHLS: true,
                    }
                }}
                width='100%'
                height='10%'
                className={'player'}
            />;
        } else if(this.state.isHls == 'other') {
             player = <ReactAudioPlayer
                src={this.state.playLink}
                loop
                autoPlay
                controls
                className={'player'}
            />;
        } else {
            const player = "";
        }
        return (
            <div>

                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}

                <div className={'dropdown_list'}>

                    <div className="dropdown mb-4 border from-control">
                        <div className={'toggle'} onClick={() => (this.closeOpen())}>

                            {this.state.itemVal.country ?
                                <>
                                    <img src={this.state.itemVal.flag} alt="flag"
                                         style={{width: '20px', height: '20px'}}/>
                                    &nbsp;
                                    {/*{this.state.itemVal.country}*/}
                                </>
                                :
                                'Select country'
                            }


                        </div>

                        <div className={this.state.close ? 'dropdown-menu' : 'dropdown-menu show'}>

                            {this.state.isLoading  && <div className={'isLoading'}><i className="fa fa-circle-o-notch"></i></div>  }

                            <ul className={"countryList"}>
                                {this.state.persons.map((item, i) => {
                                    return (
                                        // <li onClick={(e)=> this.handleChange(item) } key={i}>
                                        <li onClick={(e) => (this.itemValue(item), this.closeOpen())} key={i}>
                                            <img src={item.flag} alt="flag" style={{width: '20px', height: '20px'}}/>
                                            {/*&nbsp;*/}
                                            {/*&nbsp;*/}
                                            {/*{item.country}*/}
                                        </li>
                                    )
                                })}
                            </ul>


                        </div>

                    </div>

                    {/* rdaio_data */}

                    <div className="mbContainer">
                        <div className="text-center">
                             <h2>{this.state.country}</h2>
                            <div className="row cusRow">
                                {this.state.isLoading  && <div className={'isLoading'}>
                                    {/*<i className="fa fa-circle-o-notch"></i>*/}
                                    <div className="loader">
                                        <Loader
                                            type="Circles"
                                            color="#dc1c2c"
                                            height={50}
                                            width={100}
                                        />
                                    </div>
                                </div>  }

                                {
                                    this.state.radio.map((cat, i) =>
                                    <div className="text-center singleTv col" key={i}>
                                        <a onClick={(e) => (this.getPlayLink(cat.link))}>
                                            <div className="itemImg">
                                                <img src={cat.banner_image} className="img-fluid"
                                                     alt="img"/>
                                            </div>
                                            <div className="title">
                                                {cat.title_bn}
                                            </div>

                                        </a>
                                    </div>
                                )

                                }
                            </div>
                        </div>
                    </div>
                    {player}

                    {/* end_radio_data */}
                </div>
            </div>
        );
    }
}

export default RadioPage;