import React, {Component} from 'react';
import logo from "../img/logo.png";
import '../css/movie.css';
import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
import CompoundVideoStreamer from "vimond-replay/video-streamer/compound";
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import axios from "axios";
import { Link } from 'react-router-dom';
import MovieDetails2 from "./MovieDetails2";

class Movie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_movie: [],
            pageSize: 10,
            page: 1,
            isLoading: true,
            movie_id:'',
            play_movie:[],
            isShowPlayer: false
        }

        this.fetchNextUsers = this.fetchNextUsers.bind(this);
        this.sayHello = this.sayHello.bind(this);
        this.playMovie = this.playMovie.bind(this);

    }
    componentDidMount() {

        const { pageSize, page } = this.state
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_movie: response.data.data,
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    sayHello(e) {
        //alert(e);
        this.playMovie(e)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.all_movie.length !== this.state.all_movie.length) {
            this.setState({
                all_movie: this.state.all_movie
            })
        }
    }

    playMovie(e) {
        this.setState({
            movie_id: e
        })
        var data = JSON.stringify({"movie_id": e});

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie_details',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            },
            data : data
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                if(response.status == 200){
                    this.setState({
                        play_movie:response.data.data,
                        isShowPlayer: true
                    })
                } else {
                    window.location='/'
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchNextUsers() {
        const { pageSize, page } = this.state
        this.setState({
            page: this.state.page + 1
        })

        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_movie?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };
        axios(config)
            .then(response => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_movie: this.state.all_movie.concat(response.data.data),
                    isLoading: false
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}
                {/*<Replay source="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"*/}
                {/*        initialPlaybackProps={{ isPaused: false }}/>*/}

                {/*<Replay*/}
                {/*    source="https://live-technologies-vod.akamaized.net/movies/rangbaz/playlist.m3u8"*/}
                {/*    initialPlaybackProps={{ isPaused: false }}*/}
                {/*>*/}
                {/*    <HlsjsVideoStreamer />*/}
                {/*</Replay>*/}

                <div className="whatsNewWrap robiNewsWrap">

                    {this.state.isShowPlayer ?
                        <MovieDetails2 movie_id={this.state.movie_id} play_movie={this.state.play_movie}/>
                        :<p></p>
                    }

                    <div className="container">
                        {this.state.isShowPlayer ?
                            <div>
                                <img src={this.state.play_movie.banner_image} className="img-fluid rounded-circle single_movie_title_img"/>
                                <span className="single_movie_title">{this.state.play_movie.title_bn}</span><br/>
                                <span className="single_movie_views">{this.state.play_movie.content_hit_count} views |
                                    {this.state.play_movie.content_date}</span>
                            </div>
                            :<p></p>
                        }
                        <h2>
                            { this.state.isShowPlayer ? 'Related Movie': 'Movie List'}</h2>
                        <div className="">

                            <InfinitScroll
                                dataLength = {this.state.all_movie.length}
                                next = {this.fetchNextUsers}
                                hasMore = {true}
                                loader={<h4></h4>}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >

                                {
                                    this.state.isLoading ?
                                        <div className="loader">
                                            <Loader
                                                type="Circles"
                                                color="#dc1c2c"
                                                height={50}
                                                width={100}
                                                // timeout={1000} //3 secs
                                            />
                                        </div>
                                        :
                                        this.state.all_movie.map(cat =>
                                            this.state.movie_id == cat.id ? ''
                                                :
                                            <div className="news24Item">
                                                <Link  onClick={(e)=>(this.sayHello(cat.id))}
                                                   className="movieItemInner">
                                                    <div className="d-flex align-items-center itemInnerWrap">
                                                        <div className="itemImg">
                                                            <img src={cat.banner_image} className="img-fluid"
                                                                 alt="img"/>
                                                        </div>
                                                        <div className="info">
                                                            <div className="title">
                                                                {cat.title_bn}
                                                            </div>
                                                            <p>{cat.client_name}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                        )

                                }
                            </InfinitScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Movie;