import React, {Component} from 'react';
import ReactAudioPlayer from "react-audio-player";
import AudioPlayer from "react-h5-audio-player";
import { Replay } from 'vimond-replay';
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
import '../css/Audio.css';

class AudioDetails extends Component {
    render() {
        const replayOptions = {
            controls: {
                skipButtonOffset: -10,
                qualitySelectionStrategy: 'cap-bitrate',
                liveDisplayMode: 'clock-time',
                includeControls: [
                    'playPauseButton',
                    'timeline',
                    'timeDisplay',
                    'volume',
                    // 'skipButton',
                    // 'audioSelector',
                    // 'subtitlesSelector',
                    // 'qualitySelector',
                    // 'airPlayButton',
                    // 'pipButton',
                    'fullscreenButton',
                    // 'exitButton',
                    // 'playbackMonitor',
                    // 'bufferingIndicator'
                ]
            },
            keyboardShortcuts: {
                keyMap: {
                    togglePause: [' ', 'Enter', 'P'],
                    toggleFullscreen: 'F',
                    decreaseVolume: '-',
                    increaseVolume: '+',
                    skipBack: ',',
                    skipForward: '.',
                    toggleUserActive: 'C',
                    toggleMute: 'M'
                }
            },
            userSettings: {
                hasPrecedence: false,
                storageKey: 'replay-settings',
                settingsStoragePolicy: {
                    volume: 'local',
                    isMuted: 'local'
                }
            },
            responsivenessRules: [{
                className: 'narrow',
                width: {
                    max: 640
                }
            }, {
                className: 'medium-width',
                width: {
                    min: 640,
                    max: 1024
                }
            }, {
                className: 'wide',
                width: {
                    min: 1024
                }
            },
                { className: 'small', width: { max: 500 } },
                { className: 'big', width: { min: 750 } },
            ],
            // videoStreamer: {
            //     shakaPlayer: {
            //         customConfiguration: {
            //             streaming: {
            //                 bufferingGoal: 120
            //             }
            //         }
            //     }
            // }
        };

        return (
            <div>
                <div className="palyer_footer">
                    <ReactAudioPlayer
                        src={this.props.audioLink}
                        loop
                        autoPlay
                        controls
                    />
                </div>
                {/*<AudioPlayer*/}
                {/*    autoPlay*/}
                {/*    src="https://cms.duronto.com.bd/audio_song/new/Beporowa_Mon.mp3"*/}
                {/*    onPlay={e => console.log("onPlay")}*/}
                {/*    // other props here*/}
                {/*/>*/}
                {/*<div className="">*/}
                {/*    <Replay*/}
                {/*        source="https://mt20live-lh.akamaihd.net/i/mt20live_1@346531/master.m3u8"*/}
                {/*        title="Norwegian ski fun"*/}
                {/*        options={replayOptions}*/}
                {/*        initialPlaybackProps={{ isPaused: false }}*/}
                {/*    >*/}
                {/*        <HlsjsVideoStreamer />*/}
                {/*    </Replay>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default AudioDetails;