import React, {Component} from 'react';
import '../css/quote.css';
import logo from "../img/logo.png";
import InfinitScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
import axios from "axios";

class Quote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            all_quote:[],
            pageSize: 10,
            page: 1,
            isLoading: false
        }

        this.fetchNextUsers = this.fetchNextUsers.bind(this);
        this.sayHello = this.sayHello.bind(this);

    }

    componentDidMount() {

        const { pageSize, page } = this.state
        var config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_quote?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_quote: response.data.data,
                    isLoading: true
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    sayHello(e) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.all_quote.length !== this.state.all_quote.length) {
            this.setState({
                all_quote: this.state.all_quote
            })
        }
    }

    fetchNextUsers() {
        const { pageSize, page } = this.state
        this.setState({
            page: this.state.page + 1
        })

        var config2 = {
            method: 'get',
            url: process.env.REACT_APP_API_URL+'/api/web/v1/get_quote?page='+`${page}`+'&pageSize='+`${pageSize}`,
            headers: {
                'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
            }
        };

        axios(config2)
            .then(response=> {
                console.log(JSON.stringify(response.data));
                this.setState({
                    all_quote: this.state.all_quote.concat(response.data.data),
                    isLoading: true
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                {/* =================================log============================= */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* =================================end_logo========================= */}

                {/* =================================today_quote====================== */}
                <div className="container">
                    <h2>Today's Quote</h2>
                    <InfinitScroll
                        dataLength = {this.state.all_quote.length}
                        next = {this.fetchNextUsers}
                        hasMore = {true}
                        loader={<h4></h4>}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >

                        {
                            !this.state.isLoading ?
                                <div className="loader">
                                    <Loader
                                        type="Circles"
                                        color="#dc1c2c"
                                        height={50}
                                        width={100}
                                    />
                                </div>
                                :
                                this.state.all_quote.map(cat =>
                                    <div className="quoteItem">
                                        <a target='_blank' href={cat.link}  onClick={(e)=>(this.sayHello(cat.id))}
                                               className="quItemInner">
                                            <div className="d-flex align-items-center quoitemInnerWrap">
                                                <div className="info">
                                                    <div className="qtitle">
                                                        {cat.description}
                                                    </div>
                                                    <p>--{cat.title}</p>
                                                    <p className="views">
                                                        <i className="fa fa-clock-o" aria-hidden="true"></i> &nbsp;
                                                        {cat.content_hit_count} views
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                )

                        }
                    </InfinitScroll>
                </div>
                {/* =================================end_today_quote================== */}
            </div>
        );
    }
}

export default Quote;