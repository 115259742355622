import React, {Component} from 'react';
import {
    Link
} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import logo from "../../img/logo.png";
import radioImg from "../../img/radio.png";
import axios from 'axios';

import '../../css/home.css';
import '../../css/quiz.css';
import otp from "../../common/otp";
import Winner from "../quiz2/common/Winner";
import Fail from "../quiz2/Fail";
import QuizOtpVerify from "./QuizOtpVerify";
import Data from "../quiz6/Data";
import nextclick from "../../sound/slider.wav";
import typeclick from "../../sound/type.mp3";
import click from "../../sound/click.wav";
import { isMobile } from "react-device-detect";
import NotMobileView from "../quiz2/common/NotMobileView";
import Loader from "react-loader-spinner";

class QuizHomePage extends Component {
    constructor(props){
        super(props);
        this.state={
            msisdn:'',
            msisdnMsg_Bn:'',
            msisdnMsg_En:'',
            dobMsg_Bn:'',
            dobMsg_En:'',
            gender:'male',
            occupation:'Student',
            is_Valid: true,
            is_otp: false,
            show:false,
            isPrivacy: true,

            model_visible:false,
            winnerMsg:'',
            isQstPage:'',
            oldUser: false,
            reStart: false,
            browserName: '',
            browserVersion: '',
            isLoading: true
        }

        this.userInfo = this.userInfo.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);
        this.handleOnChangePrivacy = this.handleOnChangePrivacy.bind(this);
        this.olduserInfo = this.olduserInfo.bind(this);
        this.reStartUserInfo = this.reStartUserInfo.bind(this);
        this.reStartUserNo = this.reStartUserNo.bind(this);
        this.showPage = this.showPage.bind(this);

    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 1000);
        const get_data = localStorage.getItem('user_data');
        const userdata = JSON.parse(get_data);
        if (get_data != null){
            if (userdata.formatted_msisdn != null) {
                this.olduserInfo();
            }
        }

        const config = {
            method: 'get',
            url: 'http://fun-world.mobi/get-msisdn.php',
        };

        axios(config)
            .then(res => {
                if(res.data == 'No MSISDN Found!'){
                    this.setState({
                        msisdn: '',
                        is_otp: true
                    });
                } else {
                    this.setState({
                        msisdn: res.data,
                        is_otp: false,
                        is_Valid: false
                    });
                }
            })

        let ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        this.setState({
            browserName: M.join(' '),
            browserVersion: 22
        })

    }

    handleOnChange(event){
        //new Audio(typeclick).play();
        //alert(event.target.name)
        var newName = event.target.name;
        var newValue = event.target.value;
        this.setState({
            [newName]: newValue
        })

        if(event.target.name == 'dob'){
            const date1 = Date.now();
            const date2 = new Date(event.target.value);
            const diffInMs = Math.abs(date2 - date1);
            const ageDifMs = diffInMs / (1000 * 60 * 60 * 24);
            const ageYears = ageDifMs/365;
            //const ageDifMs = Date.now() - new Date(event.target.value);
            if(ageYears <= 7){
                //console.log("7 years er nice" + ageYears);
                const isValidAge = true;
                this.setState({
                    dobMsg_Bn: "বয়স 7 এর বেশি হতে হবে।",
                    dobMsg_En: "Age must be greater than 7.",
                    is_Valid: true
                })
            } else {
                //console.log("good" + ageYears);
                this.setState({
                    dobMsg_Bn: "",
                    dobMsg_En: "",
                    is_Valid: false
                })
            }
        }

    }

    handleOnChangePrivacy(event){
        //alert(event.target.value);
        if(event.target.value == "true"){
            this.setState({
                isPrivacy: false
            })
        } else {
            this.setState({
                isPrivacy: true
            })
        }
        new Audio(click).play();

    }

    handleKeyUp(event){

        if (typeof event.target.value !== "undefined") {


            const op = event.target.value.substr(0, 3);
            console.log(op);
            const list_op = [
                '017','013','018','016','019','014','015'
            ];
            //console.log(list_op.indexOf(op) < 0)
            if(list_op.indexOf(op) < 0){
                this.setState({
                    msisdnMsg_Bn: "দয়া করে বাংলাদেশী মুঠোফোন নম্বর লিখুন।",
                    msisdnMsg_En: "Please enter bangladeshi valid mobile number.",
                    is_Valid: true
                })
            } else {
                const pattern = new RegExp(/^[0-9\b]+$/);
                if (!pattern.test(event.target.value)) {
                    this.setState({
                        msisdnMsg_Bn: "দয়া করে মুঠোফোন নম্বর লিখুন।",
                        msisdnMsg_En: "Please enter your mobile number.",
                        is_Valid: true
                    })
                } else if (event.target.value.length != 11) {
                    this.setState({
                        msisdnMsg_Bn: "মুঠোফোন নম্বরটি 11 টি অক্ষরের হতে হবে।",
                        msisdnMsg_En: "Mobile number must be 11 character.",
                        is_Valid: true
                    })
                } else {
                    this.setState({
                        msisdnMsg_Bn: "",
                        msisdnMsg_En: "",
                        is_Valid: false
                    })
                }
            }


        }


    }
    handleLanguage(){
        const lang = localStorage.getItem('lang');
        //alert(lang);
        if(lang == 'english'){
            localStorage.setItem('lang', 'bangla');
        } else {
            localStorage.setItem('lang', 'english');
        }
        new Audio(click).play();
    }

    userInfo(event){
        event.preventDefault();
        //alert(JSON.stringify(this.state));
        this.setState({
            visible : true
        });

        new Audio(nextclick).play();

        if(this.state.is_otp){
            var data = {
                "name":this.state.name,
                "msisdn":this.state.mb,
                "loc":this.state.loc,
                "age":this.state.age,
                "occupation": this.state.occupation,
                "gender":this.state.gender,
                "dob":this.state.dob
            }
        } else {
            var data = {
                "name":this.state.name,
                "msisdn":this.state.msisdn,
                "loc":this.state.loc,
                "age":this.state.age,
                "occupation":this.state.occupation,
                "gender":this.state.gender,
                "dob":this.state.dob
            }
        }

        //alert(JSON.stringify(data));
        var data = JSON.stringify(data);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/quiz/know/v1/save_userInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then(response => {
                if(response.data.success){

                    if(this.state.is_otp){
                        const all_otp = otp.getOTP(this.state.mb);
                        localStorage.setItem('user_data_otp', JSON.stringify(response.data.data));
                        //window.location = '/quiz_otp_verify';
                        this.setState({
                            isQstPage:'qov'
                        })
                    }
                    else
                    {
                        //alert("otp not get");
                        localStorage.setItem('user_data', JSON.stringify(response.data.data));
                        //window.location = '/quiz_question';
                        this.setState({
                            isQstPage:'qq'
                        })
                    }

                } else {
                    if(response.data.message == 'winner'){
                        this.setState({
                            model_visible : true,
                            winnerMsg:'ইতিমধ্যে আপনি এই কুইজটি সম্পূর্ণ করেছেন।'
                        });
                    }
                    if(response.data.message == 'cannotdo'){
                        this.setState({
                            model_visible : true,
                            winnerMsg:'আপনি সর্বোচ্চবার(১৪ বার) চেষ্টা করেছেন।ধন্যবাদ।'
                        });
                    }
                    if(response.data.message == 'exceed'){
                        this.setState({
                            model_visible : true,
                            winnerMsg : 'আজকের ব্যবহারের সীমা(২ বার) অতিক্রম করেছেন। অনুগ্রহ করে আগামীকাল আবার চেষ্টা করুন। ধন্যবাদ।।'
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                alert(error);
            });

    }

    olduserInfo(){

        const get_data = localStorage.getItem('user_data');
        const userdata = JSON.parse(get_data);

        var data = {
            "name":userdata.name,
            "msisdn":userdata.formatted_msisdn,
            "loc":userdata.loc,
            "age":userdata.age,
            "occupation":userdata.occupation,
            "gender":userdata.gender,
            "dob":userdata.dob
        }

        //alert(JSON.stringify(data));
        var data = JSON.stringify(data);

        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/quiz/know/v1/save_userInfo',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios(config)
            .then(response => {
                //console.log(JSON.stringify(response.data.message));
                if(response.data.success){

                    this.setState({
                        isQstPage:'qq',
                        oldUser: true
                    })
                    // this.setState({
                    //     oldUser: true
                    // })

                } else {
                    if(response.data.message == 'winner'){
                        this.setState({
                            model_visible : true,
                            winnerMsg:'ইতিমধ্যে আপনি এই কুইজটি সম্পূর্ণ করেছেন।'
                        });
                    }
                    if(response.data.message == 'cannotdo'){
                        this.setState({
                            model_visible : true,
                            winnerMsg:'আপনি সর্বোচ্চবার(১৪ বার) চেষ্টা করেছেন। ধন্যবাদ।'
                        });
                    }
                    if(response.data.message == 'exceed'){
                        //alert("working");
                        this.setState({
                            model_visible : true,
                            winnerMsg : 'আজকের ব্যবহারের সীমা(২ বার) অতিক্রম করেছেন। অনুগ্রহ করে আগামীকাল আবার চেষ্টা করুন। ধন্যবাদ।।'
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                alert("error"+error);
            });

    }


    showPage(){
        this.setState({
            show: true
        })
        new Audio(nextclick).play();
    }

    reStartUserInfo(){
        this.olduserInfo();
    }

    reStartUserNo(){
        window.location="/";
        //window.open("about:blank", "_self");
        //window.close();
    }

    render() {
        const lang = localStorage.getItem('lang');

        if(this.state.browserName === "Chrome 4" || this.state.browserName === "Chrome 95" || this.state.browserName === "Netscape 5.0 (iPhone; CPU iPhone OS 14_4_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 -?"){
        } else {
            return (
                <>
                    {
                        <NotMobileView browserName={this.state.browserName} browserVersion={this.state.browserVersion} fromHomePage={"true"}/>
                    }
                </>
            );
        }


        if(this.state.oldUser){
            return (
              <>
                  {
                      <Data fromHomePage={"true"}/>
                  }
              </>
            );
        }
        {/*go_qst_page*/}
        if (this.state.isQstPage == 'qov') {
            return (
                <>
                    {
                        <QuizOtpVerify/>
                    }
                </>
            );
        } else if(this.state.isQstPage == 'qq'){
            return (
                <>
                    {
                        <Data fromHomePage={"true"}/>
                    }
                </>
            );
        }
        {/*end_go_qst_page*/}
        {/*msisdn*/}
        var msisdn_form;
        if(this.state.msisdn){
            msisdn_form = <p>{this.state.msisdn}</p>;

        } else {
            msisdn_form = <input type="number" name="mb" id="mb" onChange={this.handleOnChange} onKeyUp={this.handleKeyUp} placeholder={lang == 'english' ? 'Mobile Number' : 'মুঠোফোন নম্বর'} className="form-control" required/>;
        }
        {/*end_msisdn*/}
        if(this.state.isLoading){
            return (
                <>
                    <div className="loader">
                        <Loader
                            type="Circles"
                            color="#dc1c2c"
                            height={50}
                            width={100}
                            //timeout={1000} //3 secs
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {/* =================================log============================= */}
                    <div className="dLogo">
                        <img src={logo} className="img-fluid" alt="Logo"/>
                    </div>
                    <div className="text-center">
                        <Link onClick={this.handleLanguage}>{lang == 'english' ? 'বাংলা' : 'English'}</Link>
                    </div>
                    {/*<p>{this.state.browserName}</p>*/}
                    {/*<p>{this.state.browserVersion}</p>*/}
                    {/* =================================end_logo========================= */}
                    {/*model*/}
                    <Modal
                        show={this.state.model_visible}
                        onHide={this.state.model_visible}
                        centered='true'
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {this.state.winnerMsg}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                            <Link to="/">
                                <i className="fa fa-home"></i>  হোমপেইজে ফিরে যান
                            </Link>
                        </Modal.Body>
                    </Modal>
                    {/*end_model*/}

                    {/*restart model*/}
                    <Modal
                        show={this.state.reStart}
                        onHide={this.state.reStart}
                        centered='true'
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {lang == 'english' ? <h3>Are you sure you want to play again?'</h3>
                                    :
                                    <h3>আপনি কি আবার খেলতে চান?</h3>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{display: 'flex', justifyContent: 'center'}}>
                            <button className="btn btn-sm duronto_yes_btn" onClick={this.reStartUserInfo}>
                                {lang == 'english' ? 'Yes' : 'হ্যাঁ'}
                            </button>
                            <button className="btn btn-sm duronto_no_btn" onClick={this.reStartUserNo}>
                                {lang == 'english' ? 'No' : 'না'}
                            </button>
                        </Modal.Body>
                    </Modal>
                    {/*end_restart_model*/}

                    <div className="container">
                        <div className="row">
                            {
                                !this.state.show? <div className="col-12">
                                    <p className="quiz_rule_heder">{lang == 'english' ? 'Rules' : 'নিয়মাবলী'}</p>

                                    {lang == 'english' ?
                                        <div className="asdasdasd" style={{textAlign:'justify',fontFamily: 'Source Sans Pro', fontSize: '14px', fontWeight: '500', lineHeight: '25px'}}>
                                            <span><span>1.</span> Each winner will get 100 Taka mobile balance.</span>
                                            <span> <span>2.</span> A winner cannot try more than one.</span>
                                            <span> <span>3.</span> You can play two times a day. (If you are not the winner.)</span>
                                            <span><span>4.</span> You can try for a maximum of 7 days. (If you are not the winner.)</span>
                                            <span> <span>5.</span>
                                            <span className='kjkj'>
                                                <b>You will get: </b> <br/>
                                                - 5 Points for each correct answer in Round-1. <br/>
                                                - 10 Points for each correct answer in Round-2. <br/>
                                                - and 50 Points for each correct answer in Final Round.(If you are not the winner.)
                                            </span>
                                        </span>
                                            <span><span>6.</span> Duronto Authority reserves the right to cancel the quiz prize for providing incorrect information.</span>
                                        </div>:
                                        <div className='asdasdasd' style={{textAlign:'justify',fontFamily: 'Source Sans Pro', fontSize: '15px', fontWeight: '500', lineHeight: '25px'}}>
                                            <span> <span>১.</span> প্রত্যেক বিজয়ী ১০০ টাকা মোবাইল ব্যালেন্স পাবেন। </span>
                                            <span> <span>২.</span> প্রত্যেক বিজয়ী একবারের বেশী চেষ্টা করতে পারবেন না। </span>
                                            <span> <span>৩.</span> আপনি দিনে দুইবার খেলতে পারবেন। (আপনি যদি বিজয়ী না হন।)</span>
                                            <span> <span>৪.</span> আপনি সর্বোচ্চ ৭ দিন চেষ্টা করতে পারবেন। (আপনি যদি বিজয়ী না হন।)</span>
                                            <span> <span>৫.</span>
                                            <span className='kjkj'>
                                            <b>পয়েন্টঃ</b><br/>
                                               - রাউন্ড -১ এ প্রতিটি সঠিক উত্তরের জন্য ০৫ পয়েন্ট।<br/>
                                              - রাউন্ড -২ এ প্রতিটি সঠিক উত্তরের জন্য ১০ পয়েন্ট।<br/>
                                        - এবং ফাইনাল রাউন্ডে প্রতিটি সঠিক উত্তরের জন্য ৫০ পয়েন্ট। (আপনি যদি বিজয়ী না হন।)</span>
                                        </span>
                                            <span> <span>৬.</span> দুরন্ত কর্তৃপক্ষ ভুল তথ্য প্রদানের জন্য কুইজ পুরস্কার বাতিল করার অধিকার সংরক্ষণ করে। </span>
                                        </div>
                                    }
                                    <br/>
                                    <div className="form-check pt-1 pb-2 font-weight-bold">
                                        <input className="form-check-input" type="checkbox" onChange={this.handleOnChangePrivacy} value={this.state.isPrivacy} id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            {lang == 'english' ? 'I agree to terms and conditions' : ' আমি শর্তাবলীর সাথে একমত'}
                                        </label>
                                    </div>
                                    <br/>
                                    <center>
                                        <button className="btn btn-md duronto_btn" onClick={this.showPage} disabled={this.state.isPrivacy}>
                                            {lang == 'english' ? 'Start' : 'শুরু'}&nbsp; &nbsp;<i className="fa fa-arrow-right"></i>
                                        </button>
                                    </center>
                                </div> : null
                            }

                            {
                                this.state.show? <div className="col-12">
                                    <h2>
                                        {lang == 'english' ?
                                            'Provide your correct information to get the reward smoothly.' :
                                            'সঠিক উপায়ে পুরষ্কার নেওয়ার জন্য আপনার সঠিক তথ্য দিন'}
                                    </h2>
                                    <form className="" onSubmit={this.userInfo}>
                                        <div className="form-group">
                                            <label htmlFor="name">{lang == 'english' ? 'Name' : 'নাম'}<span className="text-danger">*</span></label>
                                            <input type="text" name="name" id="name" onChange={this.handleOnChange} placeholder={lang == 'english' ? 'Name' : 'নাম'} className="form-control" required/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="mb">{lang == 'english' ? 'Mobile Number' : 'মুঠোফোন নম্বর'}<span className="text-danger">*</span></label>
                                            {/*<input type="text" name="mb" id="mb" onChange={this.handleOnChange} className="form-control" required/>*/}
                                            {msisdn_form}
                                            <span className="duronto_txt font-italic">
                                            {lang == 'english' ? this.state.msisdnMsg_En : this.state.msisdnMsg_Bn}
                                        </span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="loc">{lang == 'english' ? 'Occupation' : 'পেশা'}<span className="text-danger">*</span></label>
                                            <select className="form-control" onChange={this.handleOnChange} name="occupation" id="occupation">
                                                <option value="Student">Student</option>
                                                <option value="Engineer">Engineer</option>
                                                <option value="Architect">Architect</option>
                                                <option value="Teacher">Teacher</option>
                                                <option value="Doctor">Doctor</option>
                                                <option value="Scientist">Scientist</option>
                                                <option value="Banker">Banker</option>
                                                <option value="Pharmacist">Pharmacist</option>
                                                <option value="Chef">Chef</option>
                                                <option value="Accountant">Accountant</option>
                                                <option value="Statistician">Statistician</option>
                                                <option value="Service Holder">Service Holder</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="loc">{lang == 'english' ? 'Address' : 'ঠিকানা'}<span className="text-danger">*</span></label>
                                            <input type="text" name="loc" id="loc" onChange={this.handleOnChange} placeholder={lang == 'english' ? 'Address' : 'ঠিকানা'} className="form-control" required/>
                                        </div>
                                        {/*<div className="form-group">*/}
                                        {/*    <label htmlFor="age">{lang == 'english' ? 'Age' : 'বয়স'}<span className="text-danger">*</span></label>*/}
                                        {/*    <input type="number" name="age" id="age" onChange={this.handleOnChange} placeholder={lang == 'english' ? 'Age' : 'বয়স'} className="form-control" required/>*/}
                                        {/*</div>*/}
                                        <div className="form-group">
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input type="radio" name="gender" value="male" className="form-check-input" onChange={this.handleOnChange} checked={this.state.gender == 'male'}/>{lang == 'english' ? 'Male' : 'পুরুষ'}
                                                </label>
                                            </div>
                                            <div className="form-check-inline">
                                                <label className="form-check-label">
                                                    <input type="radio" name="gender" value="female" className="form-check-input" onChange={this.handleOnChange} checked={this.state.gender =='female'} />{lang == 'english' ? 'Female' : 'মহিলা'}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dob">{lang == 'english' ? 'Date of Birth' : 'জন্ম তারিখ'}<span className="text-danger">*</span></label>
                                            <input type="date" name="dob" onChange={this.handleOnChange} id="dob" className="form-control" required/>
                                            <span className="duronto_txt font-italic">
                                            {lang == 'english' ? this.state.dobMsg_Bn : this.state.dobMsg_En}
                                        </span>
                                        </div>
                                        <center>
                                            <button className="btn btn-md duronto_btn" disabled={this.state.is_Valid}>
                                                {lang == 'english' ? 'Play' : 'খেলুন'} &nbsp; &nbsp;
                                                <i className="fa fa-arrow-right"></i>
                                            </button>
                                        </center>
                                    </form>

                                </div> : null
                            }

                        </div>
                    </div>



                </>
            );
        }

    }
}

export default QuizHomePage;