import React, {Component} from 'react';

import otplogo from "../img/app_icon.png";

import NotMobileView from "./quiz2/common/NotMobileView";
import QuizHomePage from "./quiz/QuizHomePage";
import QuizHomePage2 from "./quiz3/QuizHomePage";
import QuizHomePage4 from "./quiz4/QuizHomePage";
import QuizHomePage5 from "./quiz5/QuizHomePage";
import QuizHomePage6 from "./quiz6/QuizHomePage";
import axios from "axios";
import Loader from "react-loader-spinner";
import footerImg from '../img/powered-by.png';

class QuizHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: [],
            quiz_sub: '',
            isQuizSubButton: true,
            isRedirect: false,
            setQuizType: '',
            browserName: '',
            browserVersion: '',
            isLoading: false
        }

        this.handleHard = this.handleHard.bind(this);
        this.handleEasy = this.handleEasy.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleSubjectQuiz = this.handleSubjectQuiz.bind(this);
        this.handleAdQuiz = this.handleAdQuiz.bind(this);
        this.handleQuizType = this.handleQuizType.bind(this);
    }

    componentDidMount() {

        // setTimeout(() => {
        //     this.setState({
        //         isLoading: false
        //     })
        // }, 1);


        let ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        //alert(M.join(' '));
        this.setState({
            browserName: M.join(' '),
            browserVersion: 22
        })

        // const config_cat = {
        //     method: 'post',
        //     url: process.env.REACT_APP_API_URL + '/api/quiz/category/v1/get_category',
        //     headers: {
        //         'Authorization': 'Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser'
        //     }
        // };
        //
        // axios(config_cat)
        //     .then(response => {
        //         console.log(JSON.stringify(response.data));
        //         //console.log(cryptr.decrypt(response.data));
        //         // this.setState({
        //         //     category: JSON.parse(cryptr.decrypt(response.data)).data
        //         // })
        //         //console.log(JSON.parse(cryptr.decrypt(response.data)).success);
        //         const decodeData = Buffer.from(response.data, 'base64');
        //         //alert(decodeData);
        //         this.setState({
        //             category: JSON.parse(JSON.stringify(JSON.parse(decodeData).data))
        //         })
        //         //alert(JSON.stringify(JSON.parse(decodeData).data));
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }

    handleOnChange(event) {
        const newName = event.target.name;
        const newValue = event.target.value;
        this.setState({
            [newName]: newValue
        })

        if(event.target.name == 'quiz_sub'){
            this.setState({
                isQuizSubButton: false
            })
        }

    }

    handleSubjectQuiz(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state.quiz_sub));
        this.setState({
            isRedirect: true,
            setQuizType: 'subject'
        })
    }

    handleEasy() {

        this.setState({
            isRedirect: true,
            setQuizType: 'easy'
        })
    }

    handleHard() {
        //alert("hard");
        this.setState({
            isRedirect: true,
            setQuizType: 'hard'
        })
    }

    handleAdQuiz() {
        this.setState({
            isRedirect: true,
            setQuizType: 'AdQuiz'
        })
    }

    handleQuizType(e) {
        this.setState({
            isRedirect: true,
            setQuizType: e
        })
    }

    render() {

        if(this.state.browserName === "Chrome 4" ||
            this.state.browserName === "Chrome 95" ||
            this.state.browserName === "Netscape 5.0 (iPhone; CPU iPhone OS 14_4_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 -?"){
        } else {
            return (
                <>
                    {
                        <NotMobileView browserName={this.state.browserName} fromHomePage={"true"}/>
                    }
                </>
            );
        }

            if(this.state.setQuizType == 'easy'){

                return (
                    <>
                        {
                            <QuizHomePage2 fromQuizPage={"true"}/>
                        }
                    </>
                );
            } else if(this.state.setQuizType == 'hard'){

                return (
                    <>
                        {
                            <QuizHomePage fromQuizPage={"true"}/>
                        }
                    </>
                );
            } else if(this.state.setQuizType == 'AdQuiz'){

                return (
                    <>
                        {
                            <QuizHomePage4 fromQuizPage={"true"}/>
                        }
                    </>
                );
            } else if(this.state.setQuizType == 'subject'){

                return (
                    <>
                        {
                            <QuizHomePage5 fromQuizPage={"true"} subjectId={this.state.quiz_sub}/>
                        }
                    </>
                );
            } else if(this.state.setQuizType == 'knowBangladesh') {
                return (
                    <>
                        {
                            <QuizHomePage6 fromQuizPage={"true"}/>
                        }
                    </>
                )
            } else {
                return (
                    <div>
                        {/* =================================log============================= */}
                        <div className="otpheader">
                            <img src={otplogo} className="img-fluid" alt="Logo"/>
                        </div>
                        {/* =================================end_logo========================= */}
                        {/*Hello: {this.state.category}*/}
                        {/*{this.state.browserName}*/}
                        {this.state.isLoading ?

                            <div className="loader">
                                <Loader
                                    type="Circles"
                                    color="#dc1c2c"
                                    height={50}
                                    width={100}
                                    //timeout={1000} //3 secs
                                />
                            </div>

                            :

                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <span className="quizTypeHeader">দুরন্ত কুইজে আপনাকে স্বাগতম!</span><br/>
                                    </div>
                                    {/*<div className="col-12 text-center quizTypeText">*/}
                                    {/*    <h5>আপনি কোন ধরনের কুইজ খেলতে চান সেটি সিলেক্ট করুন।</h5>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 text-center quizTypeText">*/}
                                    {/*   <h5 className="prigeText">*/}
                                    {/*       <b>পুরষ্কার:</b><br/>*/}
                                    {/*        সহজ কুইজঃ ৫০ টাকা<br/>*/}
                                    {/*        কঠিন কুইজঃ ১০০টাকা<br/>*/}
                                    {/*    </h5>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-6 quizTypeButton text-right">*/}
                                    {/*    <button onClick={this.handleEasy} className="btn btn-md duronto_type_btn">সহজ</button>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-6 quizTypeButton text-center">*/}
                                    {/*    <button onClick={this.handleHard} className="btn btn-md duronto_type_btn">কঠিন</button>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 quizTypeButton d-flex justify-content-center">*/}
                                    {/*    <button onClick={this.handleAdQuiz} className="btn btn-md duronto_type_btn">প্রিমিয়াম</button>*/}
                                    {/*</div>*/}
                                    <div className="col-12 quizTypeButtonKnow d-flex justify-content-center">
                                        <button onClick={() => {
                                            this.handleQuizType("knowBangladesh")
                                        }
                                        } className="btn btn-md duronto_know_banagladesh_btn">বাংলাদেশ কে জানুন
                                        </button>
                                    </div>
                                    {/*<br/>*/}
                                    {/*<div className="col-12 text-left quizTypeText">*/}
                                    {/*    <h5><b>বিষয় নির্বাচন করুন:</b></h5> <br/>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 text-left">*/}
                                    {/*    /!*{this.state.category}*!/*/}
                                    {/*    <form className="" onSubmit={this.handleSubjectQuiz}>*/}
                                    {/*        {this.state.category.map((cat) => (*/}
                                    {/*            <div key={cat.id} className="form-group form-check">*/}
                                    {/*                <input type="radio" name="quiz_sub" value={cat.id} className="form-check-input" id={cat.id} onChange={this.handleOnChange}/>*/}
                                    {/*                    <label className="form-check-label" htmlFor={cat.id}>*/}
                                    {/*                        {cat.campaign_name_bn}*/}
                                    {/*                    </label>*/}
                                    {/*            </div>*/}
                                    {/*        ))}*/}
                                    {/*        <br/>*/}
                                    {/*        <center>*/}
                                    {/*            <button className="btn btn-md duronto_subject_btn" disabled={this.state.isQuizSubButton}>*/}
                                    {/*                খেলুন &nbsp; &nbsp;*/}
                                    {/*                <i className="fa fa-arrow-right"></i>*/}
                                    {/*            </button>*/}
                                    {/*        </center>*/}
                                    {/*    </form>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                        }
                        <div className="footerRobi text-center">
                            <img src={footerImg} alt="robi" />
                        </div>
                    </div>
                );
            }
    }
}

export default QuizHome;