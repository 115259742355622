import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom'

import '../css/header.css';
import '../css/tv.css';
import '../css/footer.css';

import {apiUrl} from '../api/api.json';
import logo from '../img/logo.png'
import footerImg from '../img/powered-by.png';


class TvPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          all_tv: []
        };

        this.sayHello = this.sayHello.bind(this);

      }
    
      componentDidMount() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch("https://apiv1.duronto.com.bd/api/web/v1/get_tv", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    all_tv: result.data
                })
            })
            .catch(error => console.log('error', error));
      }

    sayHello(e) {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer 1Duronto4mh5ln64ron5t26kpvm3toBrowser");

        var raw = JSON.stringify({"content_id":`${e}`,"src":"duronto","clickref":"23984yyljliefewc"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_API_URL+"/api/app/v2/hit_count", requestOptions)
            .then(
                response => response.text()

            )
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    render() {
        const { all_tv } = this.state;
        return (
            <div>
                {/* top_bar */}
                <div className="topBar">
                    <span className='topBarInner'>
                        <span className='title'>দুরন্ত লাইভ টিভি
                        <span className="badge badge-danger">লাইভ</span>
                        </span>
                        <Link to="/" className="homeIcon">
                            <i className="fa fa-home"></i>
                        </Link>
                    </span>
                </div>
                {/* end_top_bar */}

                {/* logo */}
                <div className="dLogo">
                    <img src={logo} className="img-fluid" alt="Logo"/>
                </div>
                {/* end_logo */}

                
                { this.state.all_tv.map(item =>


                    // <div className="mbContainer">
                    //     <div className="container text-center">
                    //         <h2>{item.category_name_bn}</h2>
                    //         <div className="row cusRow">
                    //             {item.all_tv.map(cat =>
                    //                 <div className="text-center singleTv col">
                    //                     <a href={cat.link}>
                    //                         <div className="itemImg">
                    //                             <img src={cat.banner_image} className="img-fluid"
                    //                                 alt="img"/>
                    //                         </div>
                    //                         <div className="title">
                    //                             {cat.title_bn}
                    //                         </div>
                    //
                    //                     </a>
                    //                 </div>
                    //             )}
                    //         </div>
                    //     </div>
                    // </div>

                    <div className="tvWrap">
                        <div className="container">
                            <h2>{item.category_name_bn}</h2>
                            <div className="tvSlide">

                                {item.all_tv.map(cat =>

                                    <div className="tvItem tvcusCol">
                                        <a href={cat.link} onClick={(e)=>(this.sayHello(cat.id))} className="whatsNewItemInner">
                                            <div className="itemImg">
                                                <img src={cat.banner_image} className="img-fluid"
                                                     alt="Logo"/></div>
                                            {/*<div className="info">*/}
                                            {/*    <div className="title">*/}
                                            {/*        {cat.title_bn}*/}
                                            {/*    </div>*/}
                                            {/*    <div className="exPlore d-flex justify-content-between align-items-center">*/}
                                            {/*                  <span>*/}
                                            {/*                      <span className="linked">Explore <i*/}
                                            {/*                          className="fa fa-angle-right"></i> </span>*/}
                                            {/*                  </span>*/}
                                            {/*        <span>*/}
                                            {/*                      <i className="fa fa-share-alt"></i>*/}
                                            {/*                    </span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </a>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                
                
                )}


                {/* ====================footer============================ */}
                {/* <div className="footerImg text-center">
                    <img src={footerImg} alt="robi" />
                </div> */}
                {/* ====================end_footer======================== */}


            </div>
          );
    }
}

export default TvPage;